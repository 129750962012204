// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-en-jsx": () => import("./../src/pages/404.en.jsx" /* webpackChunkName: "component---src-pages-404-en-jsx" */),
  "component---src-pages-404-fr-jsx": () => import("./../src/pages/404.fr.jsx" /* webpackChunkName: "component---src-pages-404-fr-jsx" */),
  "component---src-pages-index-en-jsx": () => import("./../src/pages/index.en.jsx" /* webpackChunkName: "component---src-pages-index-en-jsx" */),
  "component---src-pages-index-fr-jsx": () => import("./../src/pages/index.fr.jsx" /* webpackChunkName: "component---src-pages-index-fr-jsx" */)
}

